.text-left {
  text-align: left!important;
  //height: 80vh;
}
.top-container > div {
  width: 90% !important;
}
.top-section > div > h1{
  font-size: 5.5rem;
}

// iphone vertical
@media only screen and (min-width: 280px) and (max-width: 920px){
  .top-section {
    flex-direction: column;
  }
  .top-section > div > h1 {
    font-size: 3.5rem;
  }
}

@media only screen and (min-width: 992px) {
  .top-container {
    //height: 80vh;
    //background-color: lavender;
    width: 100%;
    //background: url("../../resources/img/carton-boxes.jpeg");
    //background: url("../../resources/img/boxes.jpeg");
    background-repeat: no-repeat;
    background-position: bottom left;
    background-position-x: 7rem;
    text-align: left;
    //padding-left: 3rem;
  }
  .top-container > div {
    //margin-right: unset!important;
    //width: 50%!important;
  }
  .top-container h1, .top-container .h1 {
    font-size: 4.5rem;
    font-weight: 400!important;
    line-height: 1;
  }
  .top-container h3, .h3 {
    //font-size: 1.75rem;
    font-weight: 700!important;
    font-style: italic;
  }
}
@media only screen and (min-width: 992px) and (orientation: landscape){
  .top-container {
    background-position-x: 3em;
  }
}
@media only screen and (max-width: 425px) {
  .top-container {
    text-align: left;
    //background-color: lavender;
    width: 100%;
    //background: url("../../resources/img/carton-boxes-md.jpeg");
    //background: url("../../resources/img/boxes.jpeg");
    background-repeat: no-repeat;
    background-position: top center;
    //background-position-x: 7rem;

  }
}
@media only screen and  (orientation: portrait) and (min-width: 426px) and (max-width: 767px) {
  .top-container{
    //background-color: lavender;
    width: 100%;
    //background: url("../../resources/img/carton-boxes-sm.jpeg");
    //background: url("../../resources/img/boxes.jpeg");
    background-repeat: no-repeat;
    background-position: top center;
  }
}
@media only screen and (max-width: 375px) and (min-width: 325px){
  .top-container {
    text-align: left;
    //background-color: lavender;
    width: 100%;
    //background: url("../../resources/img/carton-boxes-sm.jpeg");
    //background: url("../../resources/img/boxes.jpeg");
    background-repeat: no-repeat;
    background-position: top center;
    //background-position-x: 7rem;

  }
}
@media only screen and (min-width: 768px) {
  .top-container {
    //height: 60vh;
  }
}
@media only screen and (orientation: portrait) and (min-width: 768px)  {
  .top-container{
    //background-color: lavender;
    width: 100%;
    //background: url("../../resources/img/carton-boxes-md.jpeg");
    //background: url("../../resources/img/boxes.jpeg");
    background-repeat: no-repeat;
    background-position: top center;
  }

}
@media only screen and (max-width: 320px) {
  .top-container {
    //height: 60vh;
    background: transparent;
  }
}
@media (orientation: landscape) and (min-width: 992px) {
  section.px-lg-5{
   padding-right: 2rem!important;
   padding-left: 2rem!important;
  }
}

.top-section-text{
  font-size: 1.5rem;
  margin-bottom: 1rem;
}