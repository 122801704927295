.clickable-row:hover{
  cursor: pointer;
}
.custom-h3{
  font-size: 2.73rem!important;
}
.links-holder{
  flex-wrap: wrap;
  justify-content: space-around!important;
}
@media only screen and (min-width: 280px) and (max-width: 480px) {
  .links-holder{
    //flex-direction: column;
  }
}