.link-form-group{
  flex-direction: row;
}
@media only screen and (min-width: 280px) and (max-width: 420px) {
  .link-form-group{
    flex-direction: column;
  }
}
@media only screen and (min-width: 280px) and (max-width: 926px)
{
  .link-form-group{
    flex-direction: column;
  }
}
@media (orientation: landscape) and (min-width: 927px) and (max-width: 1180px) {
  .link-form-group{
    flex-direction: column;
  }
}