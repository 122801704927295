.copy-button {
  border-radius: 50%;
  //background-color: #f1eded;
  //padding-top: 4px;
  padding: 0.375rem 0rem;
  width: 0;
  margin: 0;
  position: relative;
  left: -50px;
  //vertical-align: auto;
  text-decoration: underline;
}
.copy-button:hover{
  cursor: pointer;
  //background-color: lightgrey;
}
.form-section{
  max-width: 700px;
}
.form-section h3 {
  font-size: 2.73rem!important;
}
#create-section{
  //border: 5px solid;
  padding-top: 2em;
  padding-bottom: 2em;
}

.swirl-out-bck {
  -webkit-animation: swirl-out-bck 0.6s ease-in both;
  animation: swirl-out-bck 0.6s ease-in both;
  animation-delay: 3s;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-11 1:3:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-out-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
}
@keyframes swirl-out-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
}

@media only screen and (min-width: 280px) and (max-width: 420px) {
  .form-section{
    padding-top: 0!important;
  }
  #create-section{
    padding-top: 0!important;
  }
  .link-form-group{
    flex-direction: column;
  }
}