@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@font-face {
  font-family: 'PTRootUIWebMedium';
  src:
          url('resources/font/PT-Root-UI_Medium.woff2') format('woff2'),
          url('resources/font/PT-Root-UI_Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PTRootUIWebRegular';
  src:
          url('resources/font/PT-Root-UI_Regular.woff2') format('woff2'),
          url('resources/font/PT-Root-UI_Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
body{
  font-family: PTRootUIWebRegular!important;
}

.font-color-blue, .font-color-blue a{
  color: #2C70F4!important;
}
.App {
  text-align: center;
}

.App-logo {
  width: auto;
  /*height: 40vmin;*/
  /*pointer-events: none;*/
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1400px) {
  .container-sm {
    max-width: 1140px!important;
  }
}
@media (min-width: 540px){
  .container, .container-sm {
    max-width: 500px!important;
  }
}
@media (min-width: 576px){
  .container, .container-sm {
    max-width: 560px!important;
  }
}
@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px!important;
  }
}
@media (min-width: 890px){
  .container, .container-md, .container-sm {
    max-width: 880px!important;
  }
}
@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px!important;
  }
}
.form-section {
  /*background-color: cadetblue;*/
}
.mt-7{
  margin-top: 5rem;
}
.btn,  input, textarea {
  border-radius: 0!important;
}