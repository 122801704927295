body {
  margin: 0;
  font-family: var(--bs-body-font-family)!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: var(--bs-body-font-family)!important;
}
