.small-card{
  //border: solid 1px grey;
  box-shadow: 3px 3px 3px grey;
  display: flex;
  background: white;
  justify-content: space-between;
  flex-direction: column;
  width: 30%;
  height: 30vh;
  min-height: 290px;
  margin: .5rem!important;
}
.small-card > h4:hover{
  cursor: pointer;
}
.valid-span {
  font-size: 12px;
}
.link-description{
  text-align: left!important;
  overflow-wrap: break-word;
}
.copy-link-icon{
  margin-right: 0rem;
}
.copy-link-icon:hover {
  cursor: pointer;
}
.copy-link-tooltip{
  position: relative;
  display: inline-block;
}
.link-tooltip{
  visibility: hidden;
  background-color: grey;
  color: white;
  text-align: center;
  padding: 3px 0;
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 105%;
  left: 50%;
  margin-left: -60px;
}

.copy-link-tooltip:hover .link-tooltip{
  visibility: visible;
}
.copy-link-tooltip:hover ~ .link-tooltip{
  visibility: visible;
}
.copy-acknowledged{
  color: forestgreen;
}
@media only screen and (orientation: portrait) and (min-width: 280px) and (max-width: 740px) {
  .small-card{
    width:100%;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}
@media (orientation: landscape) and (min-width: 321px) and (max-width: 480px) {
  .small-card{
    width: 46.4% !important;
  }
}
@media (orientation: landscape) and (min-width: 481px) and (max-width: 890px) {
  .small-card{
    width: 47% !important;
  }
}
@media (orientation: landscape) and (min-width: 891px) and (max-width: 1024px) {
  .small-card{
    width: 47% !important;
  }
}
@media (orientation: portrait) and (min-width: 891px) and (max-width: 1024px) {
  .small-card{
    width: 47% !important;
  }
}
@media (orientation: portrait) and (min-width: 481px) and (max-width: 890px) {
  .small-card{
    width: 47% !important;
  }
}