.add-icon{
  height: 14px;
  margin-left: 10px;
}
.create-new-button{
  text-decoration: none;
  font-size: 1.5rem;
}
@media only screen and (min-width: 280px) and (max-width: 890px) {
  .create-new-button{
    font-size: 1.1rem!important;
  }
}
.top-links-row{
  justify-content: space-between!important;
}
@media only screen and (min-width: 280px) and (max-width: 320px) {
  .top-links-row{
    flex-direction: column;
    margin-top: 0!important;
    //justify-content: normal!important;
  }
  .top-links-row > div:nth-child(2n){
     margin-top: 0.5rem;
  }
}