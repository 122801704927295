.step{
  text-align: left;
}
.side-block {
  width: 50%;
}
.side-image{
  max-width: 500px;
}
.step-section{
  margin-top: 3rem!important;
  margin-bottom: 3rem!important;
  padding-top: 3rem!important;
  padding-bottom: 3rem!important;

}

// iphone vertical
@media only screen and (min-width: 280px) and (max-width: 420px){
  .side-block {
    width: 100%;
    margin-top: 2rem;
  }
  .reverse{
    flex-direction: column-reverse!important;
  }
  .side-image{
    width: 100%;
  }
  .step-section{
    margin-top: 0rem!important;
    margin-bottom: 1rem!important;
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;

  }
}
@media only screen and (orientation: portrait) and (min-width: 420px) and (max-width: 720px){
  .reverse{
    flex-direction: column-reverse!important;
  }
}
@media only screen and (orientation: portrait) and (min-width: 420px) and (max-width: 926px) {
  .side-image{
    width: 100%;
  }
}
@media only screen and (orientation: landscape) and (min-width: 280px) and (max-width: 926px) {
  .side-image{
    width: 100%;
  }
}