.my-links-button{
  //font-size: 1.3rem;
  color: white;
  text-decoration: none;
}
.sandwich{
  //visibility: hidden;
}
.nav-not-mobile{
  display: none;
}
.my-links-button:hover {
  color: white;
}
.bg-blue {
  background-color: #2C70F4;
}
.ft-medium{
  font-family: PTRootUIWebMedium!important;
}
@media (orientation: portrait) {
  .topNavMobile{
    min-height: 40%!important;
  }
}
@media (orientation: landscape) and (min-width: 280px) and (max-width: 790px) {
  .topNavMobile{
    min-height: 60%!important;
  }
}
@media (orientation: landscape) and (min-width: 791px) and (max-width: 920px) {
  .topNavMobile{
    min-height: 50%!important;
  }
}
@media (orientation: landscape) and (min-width: 920px) and (max-width: 1024px) {
  .topNavMobile{
    min-height: 30%!important;
  }
}
@media (orientation: portrait) and (max-height: 480px){
  .topNavMobile{
    min-height: 40%!important;
  }
}
@media (orientation: portrait) and (min-height: 481px) and (max-height: 667px){
  .topNavMobile{
    min-height: 30%!important;
  }
}
@media (orientation: portrait) and (min-height: 668px) and (max-height: 897px){
  .topNavMobile{
    min-height: 30%!important;
  }
}
@media (orientation: portrait) and (min-height: 898px) and (max-height: 915px){
  .topNavMobile{
    min-height: 30%!important;
  }
}
@media (orientation: portrait) and (min-height: 915px) and (max-height: 1180px){
  .topNavMobile{
    min-height: 15%!important;
  }
}
.ft-size-27{
  font-size: 1.7rem!important;
}
@media only screen and (min-width: 280px) and (max-width: 790px) {
  .ft-size-27{
    font-size: 0.8rem!important;
  }

}
@media (min-width: 280px) and (max-width: 1024px) {
  .sandwich{
    visibility: visible;
  }
  .nav-not-mobile{
    visibility: visible;
  }
  .my-links-button{
    //font-size: 1.3rem;
    color: #2C70F4;
    text-decoration: none;
  }
}