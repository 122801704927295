.link-card{
  //max-width: 700px;
  overflow-wrap: break-word;
}
.card-description{
  overflow-wrap: break-word;
  margin-top: 3rem!important;
}
.link-number{
  font-size: 1.2rem;
}
.copy-icon:hover{
  cursor: pointer;
}
.share-icon{
  margin: 0.5rem;
}
.share-icon:hover{
  cursor: pointer;
}
.pl-5p{
  padding-left: 15px;
}
.pr-5p{
  padding-right: 15px;
}
.dyn-w-25{
  width:24%!important;
}
.links-wrapper > div:nth-child(1){
  margin-left: 0!important;
  margin-right: 8px;
}
.links-wrapper > div:nth-child(even) {
  margin-right: 8px;
  margin-left: 8px;
}
.links-wrapper > div:nth-child(odd) {
  margin-right: 8px;
  margin-left: 8px;
}
.links-wrapper > div:last-child {
  margin-right: 0;
  margin-left: 8px;
}


@media (orientation: portrait) and (min-width: 280px) and (max-width: 320px){
  .dyn-w-25{
    width: 100% !important;
  }
  .links-wrapper > div {
    margin-right: 0!important;
    margin-left: 0!important;
  }
  .card-description{
    margin-top: 2rem!important;
  }
}
@media (orientation: portrait) and (min-width: 321px) and (max-width: 480px) {
  .dyn-w-25{
    width: 100% !important;
  }
  .links-wrapper > div {
    margin-right: 0!important;
    margin-left: 0!important;
  }
  .card-description{
    margin-top: 2rem!important;
  }
}
@media (orientation: landscape) and (min-width: 321px) and (max-width: 480px) {
  .dyn-w-25{
    width: 48% !important;
  }
  .links-wrapper > div:nth-child(even) {
    margin-right: 0;
    margin-left: 8px;
  }
  .links-wrapper > div:nth-child(odd) {
    margin-right: 8px;
    margin-left: 0;
  }
  .card-description{
    margin-top: 1rem!important;
  }
}
@media (orientation: landscape) and (min-width: 481px) and (max-width: 890px) {
  .dyn-w-25{
    width: 48% !important;
  }
  .links-wrapper > div:nth-child(even) {
    margin-right: 0;
    margin-left: 8px;
  }
  .links-wrapper > div:nth-child(odd) {
    margin-right: 8px;
    margin-left: 0;
  }
  .card-description{
    margin-top: 1rem!important;
  }
}
@media (orientation: portrait) and (min-width: 481px) and (max-width: 890px) {
  .dyn-w-25{
    width: 48.8% !important;
  }
  .links-wrapper > div:nth-child(even) {
    margin-right: 0;
    margin-left: 8px;
  }
  .links-wrapper > div:nth-child(odd) {
    margin-right: 8px;
    margin-left: 0;
  }
  .card-description{
    margin-top: 1rem!important;
  }
}
@media (orientation: portrait) and (min-width: 891px) and (max-width: 1024px) {
  .dyn-w-25{
    width: 48.8% !important;
  }
  .links-wrapper > div:nth-child(even) {
    margin-right: 0;
    margin-left: 8px;
  }
  .links-wrapper > div:nth-child(odd) {
    margin-right: 8px;
    margin-left: 0;
  }
  .card-description{
    margin-top: 1rem!important;
  }
}
@media (orientation: landscape) and (min-width: 891px) and (max-width: 1024px) {
  .dyn-w-25{
    width: 48.8% !important;
  }
  .links-wrapper > div:nth-child(even) {
    margin-right: 0;
    margin-left: 8px;
  }
  .links-wrapper > div:nth-child(odd) {
    margin-right: 8px;
    margin-left: 0;
  }
  .card-description{
    margin-top: 1rem!important;
  }
}
@media (orientation: landscape) and (min-width: 1025px) and (max-width: 1180px) {
  .dyn-w-25{
    width: 48.8% !important;
  }
  .links-wrapper > div:nth-child(even) {
    margin-right: 0;
    margin-left: 8px;
  }
  .links-wrapper > div:nth-child(odd) {
    margin-right: 8px;
    margin-left: 0;
  }
  .card-description{
    margin-top: 1rem!important;
  }
}
@media (min-width: 1200px){
  .dyn-w-25{
    width:23.9%!important;
  }
  .card-description{
    margin-top: 1rem!important;
  }
}
//@media only screen and (min-width: 481px) and (max-width: 890px) {
//  .dyn-w-25{
//    width: 50% !important;
//  }
//}