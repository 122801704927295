html, body, #root, .App, .App > div{
  //height: 100%;
  //overflow: hidden;
}
.promo-container{
  background-color: #d3d3d333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  //width: auto;
  font-size: 3rem;
}
.box {
  opacity: 0;
  height: 7rem;
  width: 7rem;
  //z-index: 10;
  position: absolute;
  //top: 40%;
  //left: 46%;
  padding: 1em;
  border: 0.25em solid;
  box-sizing: unset;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      background: #f6f6f6;
      box-sizing: unset;
    }
    &::before {
      top: -0.3em;
      bottom: -0.3em;
      left: 1em;
      right: 1em;
    }
    &::after{
      left: -0.3em;
      right: -0.3em;
      top: 1em;
      bottom: 1em;
    }
}
.text {
  font-size: 4rem;
  letter-spacing: .7rem ;
  -webkit-animation: text 5.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: text 5.8s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.box {
  -webkit-animation: fadein 3s 1 forwards, box 4s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
  animation: fadein 3s 1 forwards, box 4s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
  animation-delay: 5.8s, 0s;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-9 16:58:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@-webkit-keyframes text {
  0% {
    letter-spacing: 2em;
    -webkit-transform: translateZ(400px) translateY(500px);
    transform: translateZ(400px) translateY(500px);
    opacity: 0;
  }
  20% {
    opacity: 0.6;
  }
  45% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
    letter-spacing: .2em;
  }
  55% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
    letter-spacing: .2em;
  }
  80% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: 2em;
    -webkit-transform: translateZ(400px) translateY(-500px);
    transform: translateZ(400px) translateY(-500px);
    opacity: 0;
  }
}

@keyframes text {
  0% {
    letter-spacing: 2em;
    -webkit-transform: translateZ(400px) translateY(500px);
    transform: translateZ(400px) translateY(500px);
    opacity: 0%;
  }
  20% {
    opacity: 0.6;
  }
  45% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
    letter-spacing: .2em;
  }
  55% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
    letter-spacing: .2em;
  }
  80% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: 2em;
    -webkit-transform: translateZ(400px) translateY(-500px);
    transform: translateZ(400px) translateY(-500px);
    opacity: 0%;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes box {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes box {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
}

