.error-modal{
  //position: absolute!important;
  position: relative!important;
  margin-top: 1rem;
  top: 10px!important;
  left: auto!important;
  right: 10px!important;
  z-index: 1200;
}
.icon-close-button{
  position: absolute;
  background-color: transparent!important;
  top: 5px;
  right: 5px;
}
.hidden{
  display: none!important;
}
.fade-out {
  animation: fadeOut ease 5s;
  -webkit-animation: fadeOut ease 5s;
  -moz-animation: fadeOut ease 5s;
  -o-animation: fadeOut ease 5s;
  -ms-animation: fadeOut ease 5s;
}

@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-ms-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}