.cursor-hover:hover{
  cursor: pointer;
}
.logout{
  padding: 1rem;
}
.drop-menu{
  flex-direction: column;
}
.dropdown-toggle::after {
  display: none !important;
}
.d-none{
  display: none !important;
}