.paginator {
  padding: 1.5rem!important;
}
.paginator > div > span,
.paginator > div > a {
  font-size: 1rem;
  font-weight: 800;
  margin: 1rem;
}
.paginator > div > a:hover {
  cursor: pointer;
}

@media only screen and (orientation: portrait) and (min-width: 280px) and (max-width: 420px) {
  .paginator > div > span,
  .paginator > div > a {
    font-size: 1rem;
    font-weight: 800;
    margin: 0.1rem;
  }
  .paginator {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
  }
}